.graph .labels.x-labels {
  text-anchor: start;
}

.graph .labels.y-labels {
  text-anchor: start;
}

.graph {
  fill: black;
}

.graph .grid {
  stroke: #ccc;
  stroke-dasharray: 0;
  stroke-width: 1;
}

.labels {
  font-size: 13px;
  stroke: #ccc;
}

.label-title {
  font-weight: bold;
  font-size: 14px;
  color: white;
  fill: white;
}
.label-axis {
  font-weight: bold;
  font-size: 12px;
  color: #ccc;
  fill: #ccc;
}
.label-subtitle {
  font-weight: bold;
  font-size: 12px;
  color: #ccc;
  fill: #ccc;
}
.data {
  fill: red;
  stroke-width: 1;
}
.axis-line {
  stroke-width: 2px;
}
.bluebar {
  fill: #00aeef;
  stroke-width: 0.2;
}
.yellowbar1 {
  fill: #ffeb80;
  stroke-width: 0.2;
}
.yellowbar2 {
  /* fill: gold; */
  fill: #ffd700;
  stroke-width: 0.2;
}
.linebar {
  fill: white;
  stroke-width: 0.5;
}
.dot-circle:hover {
  stroke-width: 3;
  stroke: white;
}
