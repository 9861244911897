.row {
  /* copied from Antd */
  position: relative;
}

.col {
  /* copied from Antd */
  position: relative;
}

.test {
  position: absolute;
  top: -100vh;
  right: -100vw;
  bottom: -100vh;
  left: -100vw;
  z-index: 2;
}

@font-face {
  font-family: "Netto Regular";
  src: url("./fonts/NettoOffcPro.ttf"); /* IE9 Compat Modes */
}
@font-face {
  font-family: "Netto bold";
  src: url("./fonts/NettoIconsOT-Bold.otf"); /* IE9 Compat Modes */
}
@font-face {
  font-family: "Netto black";
  src: url("./fonts/NettoOffcPro-Black.ttf");
}
@font-face {
  font-family: "Netto Light";
  src: url("./fonts/NettoOffcPro-Light.ttf");
}
@font-face {
  font-family: "Netto Thin";
  src: url("./fonts/NettoOffcPro-Thin.ttf");
}
