.ant-select-item-option-content {
  border-bottom: 1px solid;
  padding: 4px;
  margin: 1px;
}

.ant-select-selector {
  background: #00a76d !important;
  border: #00a76d !important;
  color: white !important;
}
.ant-select-selection-placeholder {
  color: white !important;
  text-align: center !important;
  font-weight: 600 !important;
}
.ant-select-arrow {
  color: white !important;
}
.ant-select-selector {
  font-weight: 700 !important;
  text-align: center !important;
  font-size: 20px !important;
}
.ant-select-selection-item {
  font-weight: 700 !important;
  text-align: center !important;
  font-size: 20px !important;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected {
  background-color: transparent !important;
  border-radius: 5px;
}
.chartBG {
  background-color: #2f2b2b;
}
.chartTopBtns {
  padding: 50px;
}
.chartArea {
  margin-top: 10px;
  margin-bottom: 20px;
}
.chartToggler {
  margin-right: 1%;
  /* margin-top: 1%; */
  margin-bottom: 1%;
}
.section {
  width: 100%;
  height: 100%;
  background-size: cover;
}
.overlay {
  top: 0;
  left: 0%;
  width: 100%;
  height: 100%;
  background: #44b97b;
}
.marker {
  width: 0;
  height: 0;
}

.marker span {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 30px;
  height: 30px;
  color: #249bce;
  background: #249bce;
  border: solid 2px;
  border-radius: 0 70% 70%;
  box-shadow: 0 0 2px #000;
  cursor: pointer;
  transform-origin: 0 0;
  transform: rotateZ(-135deg);
}

.marker b {
  transform: rotateZ(135deg);
}

.temporary-marker span {
  background: #00aeef;
}
.world_videos {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 550px;
  padding-left: 20px;
}
.world_videos > .ant-row > .ant-col > .ant-card > .ant-card-body {
  background-color: #00aeef !important;
  color: white !important;
  border: white !important;
  border-radius: 0% 0% 8px 8px;
  width: 327px;
  padding-left: 10px;
}
.world_videos
  > .ant-row
  > .ant-col
  > .ant-card
  > .ant-card-body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px white;
  border-radius: 10px;
}

/* Handle */
.world_videos
  > .ant-row
  > .ant-col
  > .ant-card
  > .ant-card-body::-webkit-scrollbar-thumb {
  background: white(146, 145, 145);
  border-radius: 10px;
}

/* Handle on hover */
.world_videos
  > .ant-row
  > .ant-col
  > .ant-card
  > .ant-card-body::-webkit-scrollbar-thumb:hover {
  background: white;
}
.world_videos > .ant-row > .ant-col > .ant-card-bordered {
  border: rgb(37, 38, 43) !important;
  border-radius: 10px !important;
  overflow: hidden !important;
}

.world_videos
  > .ant-row
  > .ant-col
  > .ant-card
  > .ant-card-body
  > .ant-card-meta
  > .ant-card-meta-detail
  > .ant-card-meta-title {
  color: white !important;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(146, 145, 145);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #249bce;
}
.world_videos > .ant-row {
  margin-bottom: 20px;
}
.selectedCountry {
  color: lightgray;
  font-family: "Netto Regular";
  font-size: 20px;
}
.endoCutCards > .ant-col > .ant-card > .ant-card-head {
  background-color: #ffd700;
  border-radius: 10px;
  text-align: center;
  font-weight: 800;
  font-size: 20px;
  cursor: pointer;
}
.endoCutCards > .ant-col > .ant-card {
  border: none !important;
  font-family: "Netto Regular";
}
.chartTitle > .ant-card-head {
  padding: 0px;
  align-items: center;
  text-align: center;
  font-size: 25px;
  font-family: "Netto Regular";
  font-weight: 800;
  /* border-radius: 10px !important; */
}
.chartTitle > .ant-card-body {
  padding: 0px;
}
.chartTitle {
  border: none;
}
.modeBtn {
  color: black;
  height: 45px;
  /* height: 50px; */
  border-radius: 10px;
  font-size: 20px;
  font-family: "Netto Regular";
  width: 100%;
  /* width:fit-content; */
  /* font-weight: 600; */
}
.showAllModesBtn {
  display: flex;
  justify-content: flex-start;
  padding: 0px;
  margin: 0px;
  cursor: pointer;
  background-color: #19222b;
  color: #7a8083;
  font-size: 15px;
  font-weight: bold;
  font-family: "Netto Regular";
  padding-left: 5%;
  padding-top: 10px;
}
.video-wrapper {
  max-width: 720px;
  min-width: 320px;
  position: relative;
}
.video-wrapper video {
  vertical-align: middle;
  width: 100%;
}
.video-description {
  background: transparent;
  position: absolute;
  top: -68%;
  right: 0;
  bottom: 0;
  left: -75%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.video-description img {
  filter: invert(1);
}
.highlight {
  border: 6px solid white !important;
}
.endoCut_Videos > .ant-row > .ant-col > .ant-card > .ant-card-body {
  background-color: rgb(255, 215, 0);
  font-family: "Netto Regular";
  font-weight: 900;
  margin-top: -2px;
  margin-right: -2px;
  margin-left: -2px;
  margin-bottom: -2px;
}
.loader > .ant-col > .ant-card > .ant-card-body {
  background-color: white !important;
}
.endoCut_Videos > .ant-row > .ant-col > .ant-card > .ant-card-cover {
  margin-top: -2px;
  margin-right: -2px;
  margin-left: -2px;
}
/* .endoCut_Videos ::-webkit-scrollbar {
  display: none;
} */

.endoCut_Videos {
  display: flex;
  margin-right: -15px;
  background-color: rgb(47, 43, 43);
  justify-content: center;
  margin-top: 50p;
  padding-top: 20px;
  width: 50p;
}
.share-button {
  display: none !important;
}
.player
  .player-fcefbd94-c70a-4525-9977-4fbb51d6ff6d
  .js-player-fullscreen
  .with-fullscreen
  .with-sticky-custom-logo
  .player-xxs
  .player-cardsCorner
  .player-mini {
  height: 100% !important;
}
.team{
  background-image: 
  linear-gradient(rgba(29, 207, 196, 0.5), rgba(29, 207, 196, 0.5)),
   url(../../assets/images/resize.jpg);
 background-repeat: no-repeat;
 background-size: cover;

 
}


