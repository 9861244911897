.ant-checkbox .ant-checkbox-inner {
    width: 25px;
    height: 25px;
    /* background-color: white; */
    border-color: black;
    border: 2px solid;
  }
  
  .ant-checkbox-disabled .ant-checkbox-inner {
    width: 25px;
    height: 25px;
    background-color: gray;
    border-color: gray;
  }
  
  .ant-checkbox-checked .ant-checkbox-inner {
    width: 25px;
    height: 25px;
    border-color: black;
    background-color: white;
    border: 2px solid;
  }

  .ant-checkbox-checked .ant-checkbox-inner:after {
    border-color: black !important;
    width: 10.714286px;
   height: 16.132857px;
  }