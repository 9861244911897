.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
  background-color: transparent !important;
  color: white !important;
}
.ant-layout-header {
  background: #000000 !important;
}
.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  color: rgba(255, 255, 255, 0.65);
  background: #000000 !important;
}
.ant-menu-horizontal {
  line-height: 46px;
  border: 0;
  border-bottom: none !important;
  box-shadow: none;
}
.headerPlayList > .ant-modal-title {
  font-size: 30px !important;
  font-family: "Netto Regular" !important;
  font-weight: 900 !important;
}
.headerPlayList > .ant-modal-body {
  font-family: "Netto Regular" !important;
}
